// chat.jsx
import React from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Text } from '../components/Text';
import { Secuence } from '../components/Secuence';
import { SocketContext } from '../context/SocketContext';
import { MatrixText } from '../components/TextEffects';

const styles = theme => ({
  '@global': {
    '.scrollable': {
      overflow: 'hidden !important'
    },
    '*::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'transparent'
    },
    '*::-webkit-scrollbar-thumb': {
      background: '#033232 !important',
      borderRadius: '1px'
    },
    '*::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '@media screen and (max-width: 768px)': {
      height: '100vh',
      paddingBottom: '46px'
    }
  },
  header: {
    padding: '0px 0px 0 0px'
  },
  chatWindow: {
    height: 'calc(100vh - 340px)',
    border: '1px solid #033232',
    borderRadius: 4,
    padding: 20,
    margin: '10px 0',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: 'rgba(10, 11, 15, 0.3)',
    position: 'relative',
    '@media screen and (min-width: 769px)': {
      height: 'calc(100vh - 420px)'
    },
    '@media screen and (max-width: 768px)': {
      height: 'calc(100vh - 310px)',
      padding: 10,
      margin: '5px 0',
      marginBottom: '46px',
      paddingBottom: '10px'
    }
  },
  messageItem: {
    marginBottom: 25,
    marginTop: 15,
    padding: '10px 15px',
    borderRadius: 0,
    maxWidth: '95%',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 10,
      height: 2,
      background: 'inherit',
      borderTop: 'inherit'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 10,
      height: 2,
      background: 'inherit',
      borderTop: 'inherit'
    }
  },
  userName: {
    position: 'absolute',
    top: -18,
    left: 0,
    fontSize: '12px',
    opacity: 0.7
  },
  timestamp: {
    position: 'absolute',
    bottom: -18,
    right: 0,
    fontSize: '11px',
    opacity: 0.6
  },
  userMessage: {
    background: 'rgba(30, 30, 30, 0.6)',
    marginLeft: 'auto',
    color: 'rgba(255, 255, 255, 0.9)',
    border: '1px solid #C0E4DC',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: -1,
      right: -1,
      width: 10,
      height: 10,
      borderTop: '1px solid #C0E4DC',
      borderRight: '1px solid #C0E4DC'
    },
    backgroundImage: `
      linear-gradient(45deg, transparent 98%, #C0E4DC 100%),
      linear-gradient(-45deg, transparent 98%, #C0E4DC 100%)
    `,
    backgroundPosition: 'bottom right, bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10px 10px',
    '& $userName': {
      color: '#C0E4DC'
    },
    '& $timestamp': {
      color: '#C0E4DC'
    },
    '&::before': {
      content: '"</"',
      position: 'absolute',
      top: 5,
      right: 10,
      fontSize: '10px',
      opacity: 0.3,
      color: '#C0E4DC'
    },
    '&[data-is-self="true"]': {
      background: 'rgba(40, 44, 52, 0.7)',
      border: '1px solid #88C0D0',
      '& $userName': {
        color: '#88C0D0'
      },
      '& $timestamp': {
        color: '#88C0D0'
      },
      '&::before': {
        color: '#88C0D0'
      },
      '&::after': {
        borderColor: '#88C0D0'
      },
      backgroundImage: `
        linear-gradient(45deg, transparent 98%, #88C0D0 100%),
        linear-gradient(-45deg, transparent 98%, #88C0D0 100%)
      `
    }
  },
  botMessage: {
    background: 'rgba(30, 30, 30, 0.6)',
    marginRight: 'auto',
    color: 'rgba(255, 255, 255, 0.9)',
    border: '1px solid #FEB3C0',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      width: 10,
      height: 10,
      borderTop: '1px solid #FEB3C0',
      borderLeft: '1px solid #FEB3C0'
    },
    backgroundImage: `
      linear-gradient(45deg, transparent 98%, #FEB3C0 100%),
      linear-gradient(-45deg, transparent 98%, #FEB3C0 100%)
    `,
    backgroundPosition: 'bottom right, bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10px 10px',
    '& $userName': {
      color: '#FEB3C0'
    },
    '& $timestamp': {
      color: '#FEB3C0'
    },
    '&::before': {
      content: '">_"',
      position: 'absolute',
      top: 5,
      left: 10,
      fontSize: '10px',
      opacity: 0.3,
      color: '#FEB3C0'
    }
  },
  inputContainer: {
    margin: '0',
    padding: 0,
    display: 'flex',
    gap: '0',
    background: 'transparent',
    border: 'none',
    position: 'relative',
    '@media screen and (max-width: 768px)': {
      position: 'fixed',
      bottom: '14px',
      left: 0,
      right: 0,
      padding: '0 10px',
      height: '46px',
      background: 'rgba(10, 11, 15, 0.95)',
      borderTop: '1px solid #033232',
      zIndex: 1100
    }
  },
  inputPrefix: {
    width: '40px',
    color: '#FFD700',
    fontSize: '14px',
    opacity: 0.8,
    background: 'rgba(30, 30, 30, 0.7)',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    borderRight: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '46px',
    '&::before': {
      content: '">_"',
      color: '#FFD700'
    }
  },
  input: {
    flex: 1,
    height: '46px',
    padding: '0 15px',
    borderRadius: 0,
    border: '1px solid rgba(255, 255, 255, 0.7)',
    borderLeft: 'none',
    background: 'rgba(30, 30, 30, 0.7)',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '14px',
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.4)'
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 10px rgba(255, 255, 255, 0.1)'
    }
  },
  sendButton: {
    height: '46px',
    padding: '0 20px',
    borderRadius: 0,
    border: '1px solid #FFD700',
    background: 'rgba(30, 30, 30, 0.7)',
    color: '#FFD700',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: -1,
    '&:hover': {
      background: '#2a2a2a',
      boxShadow: '0 0 15px rgba(255, 215, 0, 0.2)'
    }
  },
  messageText: {
    color: 'inherit !important',
    position: 'relative',
    paddingLeft: 15,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: 8,
      height: 1,
      background: 'currentColor',
      opacity: 0.5
    }
  },
  statusMessage: {
    background: 'rgba(30, 30, 30, 0.4)',
    margin: '10px auto',
    color: '#FFD700',
    border: '1px solid #FFD700',
    opacity: 0.8,
    textAlign: 'center',
    maxWidth: '95%',
    fontSize: '0.9em',
    padding: '8px 15px',
    animation: '$pulse 2s infinite',
    '& span': {
      color: '#FEB3C0',
      fontWeight: 'bold'
    }
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6
    },
    '50%': {
      opacity: 0.9
    },
    '100%': {
      opacity: 0.6
    }
  },
  toggleButton: {
    width: '46px',
    height: '46px',
    border: '1px solid #88C0D0',
    background: 'rgba(30, 30, 30, 0.7)',
    color: '#88C0D0',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    position: 'relative',
    outline: 'none',
    marginRight: -1,
    '&:hover': {
      background: '#2a2a2a',
      color: '#FFD700',
      borderColor: '#FFD700',
      zIndex: 1
    },
    '&[data-active="false"]': {
      background: 'rgba(30, 30, 30, 0.7)',
      color: 'rgba(255, 255, 255, 0.5)',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      boxShadow: 'none',
      '&::after': {
        display: 'none'
      }
    },
    '&[data-active="true"]': {
      background: '#1a1a1a',
      color: '#88C0D0',
      borderColor: '#88C0D0',
      boxShadow: 'inset 0 0 10px rgba(136, 192, 208, 0.3)',
      zIndex: 1,
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 6,
        width: '4px',
        height: '4px',
        background: '#88C0D0',
        borderRadius: '50%'
      }
    },
    '& $tooltip': {
      position: 'absolute',
      bottom: '100%',
      left: '100%',
      transform: 'translateX(-40%)',
      padding: '8px 12px',
      backgroundColor: 'rgba(0, 0, 0, 0.95)',
      color: '#88C0D0',
      fontSize: '12px',
      fontWeight: '500',
      letterSpacing: '0.7px',
      whiteSpace: 'nowrap',
      opacity: 0,
      visibility: 'hidden',
      transition: 'all 0.3s ease',
      marginBottom: '10px',
      border: '1px solid #88C0D0',
      boxShadow: '0 0 10px rgba(136, 192, 208, 0.3)',
      clipPath: 'polygon(0 0, 100% 0, 100% 70%, 45% 70%, 40% 100%, 35% 70%, 0 70%)',
      fontFamily: '"Courier New", monospace',
      '&::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        background: 'linear-gradient(90deg, transparent, rgba(136, 192, 208, 0.2), transparent)',
        backgroundSize: '200% 100%',
        animation: '$sweep 2s ease-in-out infinite'
      }
    },
    '&:hover $tooltip': {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateX(-40%) scale(1.05)'
    }
  },
  chatHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom: '1px solid #033232',
    background: 'rgba(10, 11, 15, 0.5)',
    marginBottom: 10
  },
  chatTitle: {
    color: '#CCCCCC',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '& .matrix-title': {
      color: '#CCCCCC',
      opacity: 0.95,
      letterSpacing: '0.5px',
      fontSize: '20px',
    },
    '& span': {
      color: '#CCCCCC',
      fontSize: '16px',
      opacity: 0.8
    }
  },
  onlineCount: {
    color: '#C0E4DC',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    '& .dot': {
      width: '8px',
      height: '8px',
      background: '#4CAF50',
      borderRadius: '50%',
      animation: '$onlineDotGlow 1.5s infinite ease-in-out',
      boxShadow: '0 0 10px rgba(76, 175, 80, 0.7)'
    }
  },
  '@keyframes onlineDotGlow': {
    '0%': {
      opacity: 0.4,
      boxShadow: '0 0 0 0 rgba(76, 175, 80, 0.7)'
    },
    '50%': {
      opacity: 1,
      boxShadow: '0 0 0 8px rgba(76, 175, 80, 0)'
    },
    '100%': {
      opacity: 0.4,
      boxShadow: '0 0 0 0 rgba(76, 175, 80, 0.7)'
    }
  },
  emptyChat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: 'rgba(255, 255, 255, 0.5)',
    textAlign: 'center',
    '& svg': {
      width: 40,
      height: 40,
      marginBottom: 10,
      opacity: 0.5
    },
    '& h3': {
      margin: '0 0 8px 0',
      fontSize: '16px',
      color: '#88C0D0'
    },
    '& p': {
      margin: 0,
      fontSize: '14px'
    }
  },
  loadingMessage: {
    textAlign: 'center',
    padding: '10px',
    color: '#FFD700',
    fontSize: '12px',
    opacity: 0.8,
    // fontFamily: "'JetBrains Mono', monospace"
  },
  tooltip: {},
  '@keyframes sweep': {
    '0%': {
      backgroundPosition: '200% 0'
    },
    '100%': {
      backgroundPosition: '-200% 0'
    }
  },
  initialLoadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    gap: '15px'
  },
  loadingDot: {
    width: '8px',
    height: '8px',
    backgroundColor: '#88C0D0',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 4px',
    animation: '$pulse 1.4s infinite ease-in-out',
    '&:nth-child(1)': {
      animationDelay: '0s'
    },
    '&:nth-child(2)': {
      animationDelay: '0.2s'
    },
    '&:nth-child(3)': {
      animationDelay: '0.4s'
    }
  },
  '@keyframes pulse': {
    '0%, 100%': {
      transform: 'scale(0.3)',
      opacity: 0.3
    },
    '50%': {
      transform: 'scale(1)',
      opacity: 1
    }
  },
  loadingText: {
    color: '#FFD700',
    fontSize: '14px',
    opacity: 0.8,
    // fontFamily: "'JetBrains Mono', monospace",
    animation: '$fadeInOut 2s infinite ease-in-out'
  },
  '@keyframes fadeInOut': {
    '0%, 100%': {
      opacity: 0.4
    },
    '50%': {
      opacity: 0.8
    }
  },
  dateDivider: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
    opacity: 0.7,
    '&::before, &::after': {
      content: '""',
      flex: 1,
      height: '1px',
      background: '#033232'
    }
  },
  dateLabel: {
    padding: '0 15px',
    color: '#88C0D0',
    fontSize: '12px',
    background: 'rgba(10, 11, 15, 0.95)',
    border: '1px solid #033232',
    margin: '0 10px'
  }
});

class Chat extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  static contextType = SocketContext;

  state = {
    message: '',
    messages: [],
    needAiResponse: true,
    onlineUsers: 0,
    page: 0,
    loading: false,
    hasMore: true,
    isInitialLoad: true,
    initialLoading: true,
    lastMessageTime: 0,
    inputDisabled: false,
    inputPlaceholder: 'Input Message...'
  };

  inputRef = React.createRef();

  componentDidMount () {
    const { socket, isReady } = this.context;
    if (socket && isReady) {
      this.initializeSocket();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { socket, isReady } = this.context;

    if ((!this.initialized && socket && isReady)) {
      this.initialized = true;
      this.initializeSocket();
    }
  }

  initializeSocket = () => {
    const { socket } = this.context;

    if (socket) {
      socket.off('chatHistory');
      socket.off('newMessage');
      socket.off('messageStatus');
      socket.off('onlineUsers');
      socket.off('moreMessages');
      socket.off('error');

      socket.emit('joinRoom', {
        roomId: 'default_room',
        page: 0
      });

      socket.on('chatHistory', ({ messages, hasMore }) => {
        console.log('Received chat history:', messages.length, 'messages');
        this.setState({
          messages: (messages || []).reverse(),
          hasMore,
          isInitialLoad: false,
          initialLoading: false
        }, () => {
          if (this.chatWindowRef) {
            this.chatWindowRef.scrollTop = this.chatWindowRef.scrollHeight;
          }
        });
      });

      socket.on('moreMessages', ({ messages, hasMore }) => {
        this.setState(prevState => ({
          messages: [...(messages || []).reverse(), ...prevState.messages], // 反转新加载的消息
          page: prevState.page + 1,
          loading: false,
          hasMore
        }), () => {
          const newScrollHeight = this.chatWindowRef.scrollHeight;
          const previousScrollHeight = this.state.previousScrollHeight;
          if (previousScrollHeight) {
            this.chatWindowRef.scrollTop = newScrollHeight - previousScrollHeight;
          }
        });
      });

      socket.on('newMessage', (message) => {
        this.setState(prevState => ({
          messages: [...prevState.messages, message]
        }), () => {
          if (this.chatWindowRef) {
            this.chatWindowRef.scrollTop = this.chatWindowRef.scrollHeight;
          }
        });
      });

      socket.on('messageStatus', (status) => {
        if (status.status === 'clear') {
          this.setState(prevState => ({
            messages: prevState.messages.filter(msg => msg.type !== 'status')
          }));
        } else {
          this.setState(prevState => ({
            messages: [...prevState.messages.filter(msg => !msg.temporary), {
              type: 'status',
              content: status.message,
              timestamp: status.timestamp,
              temporary: true
            }]
          }), () => {
            if (this.chatWindowRef) {
              this.chatWindowRef.scrollTop = this.chatWindowRef.scrollHeight;
            }
          });
        }
      });

      socket.on('onlineUsers', ({ count }) => {
        this.setState({ onlineUsers: count });
      });

      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    }
  };

  handleScroll = () => {
    const { loading, hasMore, isInitialLoad } = this.state;
    if (loading || !hasMore || isInitialLoad) return;

    const { scrollTop } = this.chatWindowRef;
    if (scrollTop === 0) {
      const currentScrollHeight = this.chatWindowRef.scrollHeight;
      this.setState({
        loading: true,
        previousScrollHeight: currentScrollHeight
      }, this.loadMoreMessages);
    }
  };

  loadMoreMessages = () => {
    const { socket } = this.context;
    const { page } = this.state;

    this.setState({ loading: true });

    socket.emit('loadMoreMessages', {
      roomId: 'default_room',
      page: page + 1
    });
  };

  componentWillUnmount () {
    const { socket } = this.context;
    if (socket) {
      socket.off('chatHistory');
      socket.off('newMessage');
      socket.off('messageStatus');
      socket.off('onlineUsers');
      socket.off('moreMessages');
      socket.off('error');
    }
  }

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleSendMessage = () => {
    const { message, needAiResponse, lastMessageTime } = this.state;
    const { socket, username, userId } = this.context;
    const now = Date.now();

    if (now - lastMessageTime < 3000) {
      const remainingTime = Math.ceil((3000 - (now - lastMessageTime)) / 1000);
      this.setState({
        inputDisabled: true,
        inputPlaceholder: `Wait ${remainingTime}s...`
      });
      
      let countdown = remainingTime;
      const timer = setInterval(() => {
        countdown--;
        if (countdown > 0) {
          this.setState({
            inputPlaceholder: `Wait ${countdown}s...`
          });
        } else {
          clearInterval(timer);
          this.setState({
            inputDisabled: false,
            inputPlaceholder: 'Input Message...'
          }, () => {
            if (this.inputRef.current) {
              this.inputRef.current.focus();
            }
          });
        }
      }, 1000);
      
      return;
    }

    if (message.trim() && socket) {
      const userMessage = {
        type: 'user',
        userId,
        username,
        content: message.trim(),
        timestamp: now
      };

      this.setState(prevState => ({
        messages: [...prevState.messages, userMessage],
        message: '',
        lastMessageTime: now,
        inputDisabled: true,
        inputPlaceholder: 'Wait 3s...'
      }), () => {
        if (this.chatWindowRef) {
          this.chatWindowRef.scrollTop = this.chatWindowRef.scrollHeight;
        }
      });

      let countdown = 3;
      const timer = setInterval(() => {
        countdown--;
        if (countdown > 0) {
          this.setState({
            inputPlaceholder: `Wait ${countdown}s...`
          });
        } else {
          clearInterval(timer);
          this.setState({
            inputDisabled: false,
            inputPlaceholder: 'Input Message...'
          }, () => {
            if (this.inputRef.current) {
              this.inputRef.current.focus();
            }
          });
        }
      }, 1000);

      socket.emit('chatMessage', {
        message: message.trim(),
        roomId: 'default_room',
        needAiResponse
      });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSendMessage();
    }
  };

  handleToggleAiResponse = () => {
    this.setState(prevState => ({
      needAiResponse: !prevState.needAiResponse
    }));
  };

  formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  render () {
    const { classes } = this.props;
    const { message, messages, onlineUsers, loading, needAiResponse, initialLoading } = this.state;
    const { userId } = this.context;

    const renderMessages = () => {
      let currentDate = '';
      return messages.map((msg, index) => {
        const messageDate = this.formatDate(msg.timestamp);
        let dateDivider = null;
        
        if (messageDate !== currentDate) {
          currentDate = messageDate;
          dateDivider = (
            <div key={`date-${index}`} className={classes.dateDivider}>
              <span className={classes.dateLabel}>{messageDate}</span>
            </div>
          );
        }

        return (
          <React.Fragment key={`msg-group-${index}`}>
            {dateDivider}
            <div
              className={`${classes.messageItem} ${
                msg.type === 'status' ? classes.statusMessage
                  : msg.type === 'user' ? classes.userMessage
                    : classes.botMessage
              }`}
              data-is-self={msg.type === 'user' && msg.userId === userId}
            >
              {msg.type !== 'status' && (
                <>
                  <div className={classes.userName}>
                    {msg.type === 'user'
                      ? (msg.userId === userId
                        ? `${msg.username} (you)`
                        : msg.username)
                      : msg.type === 'ai' && msg.replyTo
                        ? `Soul → ${msg.replyTo}`
                        : 'Soul'
                    }
                  </div>
                </>
              )}
              <span className={classes.messageText}>{msg.content}</span>
              {msg.type !== 'status' && (
                <div className={classes.timestamp}>
                  {new Date(msg.timestamp).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </div>
              )}
            </div>
          </React.Fragment>
        );
      });
    };

    return (
      <Main className={`${classes.root} scrollable`}>
        <div className={classes.chatHeader}>
          <div className={classes.chatTitle}>
            <MatrixText 
              text="Soul Chat (Public)" 
              className="matrix-title"
            /> 
            {/* <span>Public Chat</span> */}
          </div>
          <div className={classes.onlineCount}>
            <div className='dot' />
            {onlineUsers} online
          </div>
        </div>
        <div
          className={classes.chatWindow}
          ref={ref => (this.chatWindowRef = ref)}
          onScroll={this.handleScroll}
        >
          {initialLoading ? (
            <div className={classes.initialLoadingContainer}>
              <div className={classes.loadingText}>
                Loading chat history...
              </div>
            </div>
          ) : messages.length === 0 ? (
            <div className={classes.emptyChat}>
              <h3>No messages yet</h3>
              <p>Start a conversation!</p>
            </div>
          ) : (
            <>
              {loading && (
                <div className={classes.loadingMessage}>
                  Loading more messages...
                </div>
              )}
              {renderMessages()}
            </>
          )}
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputPrefix} />
          <button
            className={classes.toggleButton}
            data-active={needAiResponse}
            onClick={this.handleToggleAiResponse}
          >
            <div className={classes.tooltip}>
              {needAiResponse ? 'AI Response Enabled' : 'AI Response Disabled'}
            </div>
            @
          </button>
          <input
            ref={this.inputRef}
            type='text'
            className={classes.input}
            value={message}
            onChange={this.handleMessageChange}
            onKeyPress={this.handleKeyPress}
            placeholder={this.state.inputPlaceholder}
            disabled={this.state.inputDisabled}
          />
          <button
            className={classes.sendButton}
            onClick={this.handleSendMessage}
          >
            Send
          </button>
        </div>
      </Main>
    );
  }
}

export default withStyles(styles)(Chat);
